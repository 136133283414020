import React from 'react';

class Row extends React.Component {

    state = {
        isDoen: false
    }

    render() {
        return (
            <div className="flex justify-between">
                <p className={this.state.isDoen ? "opacity-50" : null}>
                    {
                        this.state.isDoen ? <em><s>{this.props.content}</s></em> : this.props.content
                    }
                </p>
                <input onClick={this.isDoen} type="checkbox" id="isDoen" name="isDone"></input>
            </div>
        );
    }

    isDoen = () => {
        this.setState({ isDoen: !this.state.isDoen })
    }
}

export default Row;
