import React from 'react';

class Title extends React.Component {

    render() {
        return (
            <h1 className="flex justify-center items-center bg-cyan-950 text-white text-3xl py-3 font-bold">
                TODO LIST
            </h1>
        );
    }
}

export default Title;
