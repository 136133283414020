import React from 'react';
import Title from './Title';
import Input from './Input';
import Row from './Row';

class TodoList extends React.Component {

    state = {
        row: []
    }

    render() {
        return (
            <div>
                <Title />
                <Input addRow={this.addRow} />
                <ul className="mx-6">
                    {
                        this.state.row.map((todo, index) => {
                            return (
                                <div key={index}>
                                    {index === 0 ? <hr /> : null}
                                    <li className="my-3"><Row {...todo} /></li>
                                    <hr />
                                </div>
                            )
                        })
                    }
                </ul>
            </div>
        );
    }

    // 將可以改變 TodoList 的 Row 的方法，通過 props 賦予 Input
    addRow = (content) => {
        const newState = { ...this.state }
        const newrRow = [...newState.row, { content }]

        newState.row = newrRow
        this.setState(newState)
    }
}

export default TodoList;
