import React from "react";

class TodoInput extends React.Component {

    render() {
        return (
            <div className="flex justify-center items-center px-1 py-1 mx-3 my-3">
                <input className="w-screen border-2 border-gray-250 pl-3 py-2" onChange={this.typeTodo} onKeyDown={this.submitTodo} type="text" placeholder="插入TODO事項" ></input>
            </div>
        );
    }

    submitTodo = (event) => {

        // pressed enter
        if (event.keyCode === 13) {
            this.props.addRow(event.target.value)
            event.target.value = ""
        }
    }
}

export default TodoInput;
